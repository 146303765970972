import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Article from 'src/components/Article'
import { EssayQuery, SitePageContext } from 'types/graphql-types'
import PrevNext from 'src/components/PrevNextNavigation'

const Essay: React.FC<PageProps<EssayQuery, SitePageContext>> = ({
  data,
  pageContext,
}) => {
  if (!data) return null
  const post = data.essay?.data!
  return (
    <>
      <SEO
        title={post.title?.text}
        meta={[
          { name: 'og:image', content: post.hero?.url! },
          { name: 'og:description', content: post.excerpt || '' },
          { name: 'twitter:image', content: post.hero?.url! },
          { name: 'twitter:description', content: post.excerpt || '' },
        ]}
      />
      <Layout>
        <img src={post.hero?.url!} className="max-w-full pb-16" />
        <h1 className="text-xl md:text-2xl pb-16">{post.title?.text}</h1>
        <div className="border-black border-t w-8 mb-16" />
        <Article>
          <div
            dangerouslySetInnerHTML={{
              __html: `${post.body?.html}`,
            }}
          />
        </Article>
        <div className="pt-48">
          <PrevNext previous={pageContext.previous} next={pageContext.next} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query Essay($id: String!) {
    essay: prismicEssays(id: { eq: $id }) {
      data {
        body {
          html
        }
        title {
          text
        }
        hero {
          url
        }
        excerpt
      }
    }
  }
`

export default Essay

import React from 'react'
import { Link } from 'gatsby'
import { SitePageContext } from 'types/graphql-types'
import { VscChevronLeft } from '@react-icons/all-files/vsc/VscChevronLeft'
import { VscChevronRight } from '@react-icons/all-files/vsc/VscChevronRight'

const Titles: React.FC<Pick<SitePageContext, 'next' | 'previous'>> = ({
  next,
  previous,
}) => (
  <div className="md:text-lg">
    {(previous || next) && (
      <div className="border-black border-t w-32 ml-auto mr-0" />
    )}
    {previous && (
      <div className="pt-12">
        <Link to={`../${previous.uid}`}>
          <div className="flex items-center">
            <VscChevronLeft />
            <div className="pl-2">{previous.data?.title?.text}</div>
          </div>
        </Link>
      </div>
    )}
    {next && (
      <div className="pt-12">
        <Link to={`../${next.uid}`}>
          <div className="flex items-center justify-end">
            <div className="pr-2">{next.data?.title?.text}</div>
            <VscChevronRight />
          </div>
        </Link>
      </div>
    )}
  </div>
)

export default Titles
